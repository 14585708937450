/* Dashboard */

.card-statistics {
  .highlight-icon {
    height: 53px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    i {
      font-size: 27px;
    }
  }

  .card-col {
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: none;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      border: none;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {

        &:after,
        &:before {
          content: "";
          position: absolute;
          background: $border-color;
        }
      }

      &:first-child {
        &:before {
          bottom: 0;
          width: 94.2%;
          height: 1px;
          right: 0;
        }

        &:after {
          bottom: 0;
          width: 1px;
          height: 100%;
          right: 0;
        }
      }

      &:nth-child(2) {
        &:before {
          bottom: 0;
          width: 94.2%;
          height: 1px;
          left: 0;
        }
      }

      &:nth-child(3) {
        &:before {
          width: 1px;
          height: 100%;
          right: 0;
        }
      }
    }
  }
}

.card-revenue-table {
  .revenue-item {
    border-bottom: 1px solid $border-color;
    @extend .py-3;

    &:last-child {
      border-bottom: 0;
      @extend .pb-0;
    }

    &:first-child {
      @extend .pt-0;
    }

    .revenue-desc {
      margin-right: auto;
      width: 80%;

      p {
        margin-bottom: 0;
      }
    }

    .revenue-amount {
      margin-left: auto;
      width: 40%;

      p {
        font-size: 1.25rem;
        font-family: $type-1;
        font-weight: 600;
        text-align: right;

        .rtl & {
          text-align: left;
        }
      }
    }
  }
}

.card-revenue {
  background: linear-gradient(120deg, #556cdc, #128bfc, #18bef1);
  background-size: cover;
  color: color(white);

  .highlight-text {
    font-size: 1.875rem;
    font-family: $type-1;
    font-weight: 500;
  }

  .badge {
    background-color: rgba(color(white), .2);
    font-size: 1.125rem;
    padding: 0.5rem 1.25rem;
  }
}

.card-weather {
  background: $card-bg;

  .card-body {
    background: $white;

    &:first-child {
      background: url("../../images/samples/weather.svg") no-repeat center;
      background-size: cover;
    }
  }

  .weather-date-location {
    padding: 0 0 38px;
  }

  .weather-data {
    padding: 0 0 4.75rem;

    i {
      font-size: 5.313rem;
      line-height: 1;
    }
  }

  .weakly-weather {
    background: $card-bg;
    overflow-x: auto;

    .weakly-weather-item {
      flex: 0 0 14.28%;
      border-right: 1px solid $border-color;
      padding: 1rem;
      text-align: center;

      i {
        font-size: 1.2rem;
      }

      &:last-child {
        border-right: 0;
      }

      .symbol {
        color: $text-muted;
        font-size: 1.875rem;
        font-weight: 300;
      }
    }
  }
}

.product-chart-wrapper {
  height: 92%;
}

#dashboardTrendingProgress {
  width: 60px;
}

.dashboard-bar-chart-legend {
  .col {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .bg {
      margin-left: auto;
      margin-right: auto;
      height: 5px;
      width: 30px;
      display: block;
      margin-top: 5px;
    }

    &:nth-child(1) {
      .bg {
        background: theme-color(info);
      }
    }

    &:nth-child(2) {
      .bg {
        background: theme-color(primary);
      }
    }

    &:nth-child(3) {
      .bg {
        background: theme-color(danger);
      }
    }
  }
}

.intro-banner {
  background: #dbe4ec;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 2px;

  .banner-image {
    width: 24%;
    max-width: 100%;

    img {
      display: block;
      margin: auto;
    }
  }

  .content-area {
    width: 58%;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

.sales-mini-tabs {
  border-bottom: none;

  .nav-item {
    .nav-link {
      border: none;
      font-weight: 500;
      padding: 5px 15px;
      border-radius: 2px;
      transition-duration: 0.7s;
      transition-property: "color";

      &.active {
        font-weight: 600;
        background: lighten($card-bg-varient, 4%);
        color: theme-color(primary);
      }
    }
  }
}